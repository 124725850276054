@charset "UTF-8";
/*
 *  Document   : main
 *  Author     : bhWebDesigner
 *  Description: Main SCSS file where all required variables and styles are imported
 *
 */
/* Template Variables */
/*
 *  Document   : variables
 *  Author     : bhWebDesigner
 *  Description: Variables for changing color
 *
 */
/*
 * Color Theme
 *
 */
/* Main Colors */
/* Navbar Inverse Color */
/* Default Button */
/* Primary Button */
/*
 * Various Options
 *
 */
/* Main Template */
/*
 *  Document   : layout
 *  Author     : bhWebDesigner
 *
 *  MAIN LAYOUT
 *  Template’s main layout styles
 *
 */
/* Include Open Sans font from Google Web Fonts */
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,400italic,600,600italic,700,700italic");
/* Preloader */
#page-wrapper.page-loading {
  overflow: hidden;
}

#page-wrapper.page-loading .preloader {
  display: block;
}

#page-wrapper.page-loading #page-container {
  opacity: 0;
}

.preloader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  z-index: 99999;
}

.preloader .inner {
  position: fixed;
  top: 20%;
  left: 50%;
  margin: 0 0 0 -50px;
  width: 100px;
  height: 100px;
  text-align: center;
}

.preloader-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid transparent;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
  border-top-color: #ffffff;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner .4s linear infinite;
  -moz-animation: nprogress-spinner .4s linear infinite;
  -ms-animation: nprogress-spinner .4s linear infinite;
  -o-animation: nprogress-spinner .4s linear infinite;
  animation: nprogress-spinner .4s linear infinite;
}

.preloader-spinner {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-width: 3px;
}

/* Main Structure */
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #232824;
  font-size: 13px;
  background-color: #222222;
}

#page-container,
#main-container,
#page-content + footer {
  min-width: 320px;
}

#page-container {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  overflow-x: hidden;
  -webkit-transition: background-color 0.2s ease-out, opacity 0.3s ease-out;
  transition: background-color 0.2s ease-out, opacity 0.3s ease-out;
}

#page-container,
#sidebar,
#sidebar-alt {
  background-color: #232824;
}

#sidebar,
#sidebar-alt {
  width: 0;
  position: absolute;
  overflow: hidden;
}

#sidebar-alt {
  right: 0;
}

#sidebar,
#sidebar-alt,
#main-container,
.header-fixed-top header,
.header-fixed-bottom header,
.footer-fixed #page-content + footer {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

#page-content {
  padding: 10px 5px 1px;
  background-color: #d8d8d8;
}

#page-content + footer {
  padding: 9px 10px;
  font-size: 11px;
  background-color: #ffffff;
  border-top: 1px solid #c6cbbf;
}

/* Fixed Header */
#page-container.header-fixed-top {
  padding: 50px 0 0;
}

#page-container.header-fixed-bottom {
  padding: 0 0 50px;
}

/* Sidebar + Static Header */
.sidebar-visible-xs #sidebar {
  width: 200px;
}

.sidebar-visible-xs #main-container {
  margin-left: 200px;
  margin-right: -200px;
}

/* Sidebar + Fixed Header */
.header-fixed-top #sidebar,
.header-fixed-bottom #sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.header-fixed-top .sidebar-content,
.header-fixed-bottom .sidebar-content {
  padding-bottom: 50px;
}

.sidebar-visible-xs.header-fixed-top header.navbar-fixed-top,
.sidebar-visible-xs.header-fixed-bottom header.navbar-fixed-bottom {
  left: 200px;
  right: -200px;
}

/* Alternative Sidebar + Static Header */
.sidebar-alt-visible-xs #sidebar-alt {
  width: 200px;
}

.sidebar-alt-visible-xs #main-container {
  margin-right: 200px;
  margin-left: -200px;
}

/* Alternative Sidebar + Fixed Header */
.header-fixed-top #sidebar-alt,
.header-fixed-bottom #sidebar-alt {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
}

.sidebar-alt-visible-xs.header-fixed-top header.navbar-fixed-top,
.sidebar-alt-visible-xs.header-fixed-bottom header.navbar-fixed-bottom {
  right: 200px;
  left: -200px;
}

/* Fixed Footer */
.footer-fixed #page-content + footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.footer-fixed #page-content {
  padding-bottom: 41px;
}

.footer-fixed.sidebar-visible-xs #page-content + footer {
  right: -200px;
  left: 200px;
}

.footer-fixed.sidebar-alt-visible-xs #page-content + footer {
  right: 200px;
  left: -200px;
}

.footer-fixed.header-fixed-bottom #page-content + footer {
  bottom: 50px;
}

/*
 *  Document   : header
 *  Author     : bhWebDesigner
 *
 *  HEADER
 *  Template's custom header related styles
 *
 */
header.navbar-default,
header.navbar-inverse {
  padding: 0;
  margin: 0;
  min-width: 320px;
  border: none;
}

header.navbar-default.navbar-fixed-top {
  border-bottom: 1px solid #d8d8d8;
}

header.navbar-default.navbar-fixed-bottom {
  border-top: 1px solid #d8d8d8;
}

header.navbar-inverse.navbar-fixed-top {
  border-bottom: 1px solid #232824;
}

header.navbar-inverse.navbar-fixed-bottom {
  border-top: 1px solid #232824;
}

/* Header Nav */
.navbar-default .navbar-nav > li > a {
  color: #232824;
}

.navbar-inverse .navbar-nav > li > a {
  color: #ffffff;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  color: #ffffff;
  background-color: #7dc419;
}

.navbar-inverse .collapse.in .navbar-nav .open .dropdown-menu > li > a {
  color: #dddddd;
}

.nav.navbar-nav-custom {
  float: left;
  margin: 0;
}

.nav.navbar-nav-custom > li {
  min-height: 50px;
  float: left;
}

.nav.navbar-nav-custom > li > a {
  min-width: 50px;
  padding: 5px 7px;
  line-height: 40px;
  text-align: center;
  color: #232824;
  position: relative;
}

.nav.navbar-nav-custom > li > a .gi,
.nav.navbar-nav-custom > li > a .hi,
.nav.navbar-nav-custom > li > a .si,
.nav.navbar-nav-custom > li > a .fi {
  margin-top: -3px;
}

.nav.navbar-nav-custom > li > a .label-indicator {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  font-weight: 600;
}

.navbar-fixed-bottom .nav.navbar-nav-custom > li > a .label-indicator {
  top: auto;
  bottom: 0;
}

.navbar-inverse .nav.navbar-nav-custom > li > a {
  color: #ffffff;
}

.nav.navbar-nav-custom > li.open > a,
.nav.navbar-nav-custom > li > a:hover,
.nav.navbar-nav-custom > li > a:focus {
  background-color: #7dc419;
  color: #ffffff;
}

.nav.navbar-nav-custom > li > a > img {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 20px;
  vertical-align: top;
}

/* Header Search */
.navbar-form {
  margin: 7px 0;
}

.collapsing .navbar-form,
.collapse.in .navbar-form {
  margin-left: -15px;
  margin-right: -15px;
}

.collapsing .navbar-form .form-group,
.collapse.in .navbar-form .form-group {
  margin-bottom: 0;
}

.navbar-form-custom {
  padding: 0;
  width: 280px;
  float: left;
  height: 50px;
}

.navbar-form-custom .form-control {
  padding: 10px;
  margin: 0;
  height: 50px;
  font-size: 15px;
  background: transparent;
  border: none;
  z-index: 2000;
}

.navbar-form-custom .form-control:hover,
.navbar-form-custom .form-control:focus {
  background-color: #ffffff;
}

.navbar-form-custom .form-control:focus {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 18px;
  padding: 10px 20px;
}

.navbar-inverse .navbar-form-custom .form-control {
  color: #ffffff;
}

.navbar-inverse .navbar-form-custom .form-control:hover,
.navbar-inverse .navbar-form-custom .form-control:focus {
  background: #000000;
  color: #ffffff;
}

/*
 *  Document   : sidebar_nav
 *  Author     : bhWebDesigner
 *
 *  SIDEBAR/NAVIGATION
 *  Sidebars’ content related styles
 *
 */
/* Sidebar Content */
.sidebar-content {
  width: 200px;
  color: #ffffff;
}

.sidebar-section {
  padding: 10px;
}

/* Sidebar Brand + Title */
.sidebar-brand,
.sidebar-title {
  height: 50px;
  line-height: 46px;
  padding: 0 10px;
  margin: 0;
  font-weight: 300;
  font-size: 18px;
  display: block;
  color: #7dc419;
  background-color: #FFFFFF;
}

a.sidebar-brand:hover,
a.sidebar-brand:focus,
a.sidebar-title:hover,
a.sidebar-title:focus {
  background: #d8d8d8;
}

.sidebar-brand i,
.sidebar-title i {
  font-size: 14px;
  display: inline-block;
  width: 18px;
  text-align: center;
  margin-right: 10px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.sidebar-title i.pull-left {
  margin: 20px 10px 0 0;
}

.sidebar-title i.pull-right {
  margin: 20px 0 0 10px;
}

/* Sidebar User */
.sidebar-user {
  padding-left: 88px;
  background: url("../img/template/ie8_opacity_light_10.png") repeat;
  background: rgba(255, 255, 255, 0.1);
}

.sidebar-user-avatar {
  width: 68px;
  height: 68px;
  float: left;
  padding: 2px;
  margin-left: -78px;
  border-radius: 34px;
  background: url("../img/template/ie8_opacity_light_75.png") repeat;
  background: rgba(255, 255, 255, 0.75);
}

.sidebar-user-avatar img {
  width: 64px;
  height: 64px;
  border-radius: 32px;
}

.sidebar-user-name {
  font-size: 17px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 26px;
}

.sidebar-user-links a {
  color: #ffffff;
  opacity: 0.3;
  filter: alpha(opacity=30);
  margin-right: 5px;
}

.sidebar-user-links a:hover,
.sidebar-user-links a:focus {
  color: #ffffff;
  text-decoration: none;
  opacity: 1;
  filter: alpha(opacity=100);
}

.sidebar-user-links a > i {
  font-size: 14px;
}

/* Sidebar Color Themes */
.sidebar-themes {
  list-style: none;
  margin: 0;
  padding-top: 12px;
  padding-bottom: 6px;
  background: url("../img/template/ie8_opacity_dark_15.png") repeat;
  background: rgba(0, 0, 0, 0.15);
  opacity: .5;
  filter: alpha(opacity=50);
  -webkit-transition: opacity .25s ease-out;
  transition: opacity .25s ease-out;
}

.sidebar-themes:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.sidebar-themes li {
  float: left;
  margin: 0 5px 6px 0;
}

.sidebar-themes li a {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
}

.sidebar-themes li a:hover,
.sidebar-themes li a:focus {
  border-color: #ffffff !important;
}

.sidebar-themes li.active a {
  border-color: #ffffff !important;
}

/* Sidebar Chat */
.chat-users {
  list-style: none;
  margin: 0;
  padding: 10px;
}

.chat-users li {
  float: left;
  margin: 0;
  padding: 4px;
}

.chat-users a {
  position: relative;
  display: inline-block;
  padding: 2px;
  width: 52px;
  height: 52px;
  border-radius: 25px;
  background: url("../img/template/ie8_opacity_light_10.png") repeat;
  background: rgba(255, 255, 255, 0.1);
}

.chat-users a span {
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #cccccc;
}

.chat-users a img {
  width: 48px;
  height: 48px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.chat-users a.chat-user-online,
.chat-users a.chat-user-away,
.chat-users a.chat-user-busy {
  background: url("../img/template/ie8_opacity_light_75.png") repeat;
  background: rgba(255, 255, 255, 0.75);
}

.chat-users a.chat-user-online span {
  background-color: #2ecc71;
}

.chat-users a.chat-user-away span {
  background-color: #f39c12;
}

.chat-users a.chat-user-busy span {
  background-color: #e74c3c;
}

.chat-users a:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 4px 2px #999999;
  box-shadow: 0 0 4px 2px #999999;
}

.chat-users a:hover img,
.chat-users a.chat-user-online img,
.chat-users a.chat-user-away img,
.chat-users a.chat-user-busy img {
  opacity: 1;
  filter: alpha(opacity=100);
}

.chat-talk-info {
  line-height: 22px;
  font-size: 13px;
}

.chat-talk-info img {
  width: 22px;
  height: 22px;
  float: left;
  margin-right: 10px;
}

.chat-talk-messages {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  background: url("../img/template/ie8_opacity_dark_20.png") repeat;
  background: rgba(0, 0, 0, 0.2);
}

.chat-talk-messages li {
  padding: 7px 10px;
  margin-bottom: 7px;
}

.chat-talk-messages .chat-talk-msg {
  width: 180px;
  margin-right: 20px;
  border-right: 3px solid #fff;
  background: url("../img/template/ie8_opacity_dark_40.png") repeat;
  background: rgba(0, 0, 0, 0.4);
}

.chat-talk-messages .chat-talk-msg.chat-talk-msg-highlight {
  margin-right: 0;
  margin-left: 20px;
  border-right: none;
  border-left-width: 3px;
  border-left-style: solid;
}

.chat-form {
  margin: 0;
  padding: 5px 10px;
  background: url("../img/template/ie8_opacity_dark_40.png") repeat;
  background: rgba(0, 0, 0, 0.4);
}

.chat-form .form-control {
  background: transparent;
  color: #eeeeee;
}

/* Sidebar Navigation */
.sidebar-nav {
  list-style: none;
  margin: 0;
  padding: 10px 0 0;
}

.sidebar-nav .sidebar-header:first-child {
  margin-top: 0;
}

.sidebar-nav a {
  display: block;
  color: #d8d8d8;
  padding: 0 10px;
  min-height: 35px;
  line-height: 35px;
}

.sidebar-nav a:hover,
.sidebar-nav a.open,
.sidebar-nav li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: url("../img/template/ie8_opacity_dark_15.png") repeat;
  background: rgba(0, 0, 0, 0.15);
}

.sidebar-nav a.active {
  padding-left: 5px;
  border-left: 5px solid #7dc419;
  background: url("../img/template/ie8_opacity_dark_30.png") repeat;
  background: rgba(0, 0, 0, 0.3);
}

.sidebar-nav a > .sidebar-nav-icon {
  margin-right: 10px;
}

.sidebar-nav a > .sidebar-nav-indicator {
  float: right;
  line-height: inherit;
  margin-left: 4px;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.sidebar-nav a > .sidebar-nav-icon,
.sidebar-nav a > .sidebar-nav-indicator {
  display: inline-block;
  opacity: 0.5;
  filter: alpha(opacity=50);
  width: 18px;
  font-size: 14px;
  text-align: center;
}

.sidebar-nav a:hover,
.sidebar-nav a:hover > .sidebar-nav-icon,
.sidebar-nav a:hover > .sidebar-nav-indicator,
.sidebar-nav a.active,
.sidebar-nav a.active > .sidebar-nav-icon,
.sidebar-nav a.active > .sidebar-nav-indicator,
.sidebar-nav a.open,
.sidebar-nav a.open > .sidebar-nav-icon,
.sidebar-nav a.open > .sidebar-nav-indicator,
.sidebar-nav li.active > a,
.sidebar-nav li.active > a > .sidebar-nav-icon,
.sidebar-nav li.active > a > .sidebar-nav-indicator {
  opacity: 1;
  filter: alpha(opacity=100);
}

.sidebar-nav a.active > .sidebar-nav-indicator,
.sidebar-nav a.open > .sidebar-nav-indicator,
.sidebar-nav li.active > a > .sidebar-nav-indicator {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidebar-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  background: url("../img/template/ie8_opacity_dark_30.png") repeat;
  background: rgba(0, 0, 0, 0.3);
}

.sidebar-nav li.active > ul {
  display: block;
}

.sidebar-nav ul a {
  margin: 0;
  font-size: 12px;
  padding-left: 15px;
  min-height: 32px;
  line-height: 32px;
}

.sidebar-nav ul a.active,
.sidebar-nav ul a.active:hover {
  border-left: 5px solid #7dc419;
  padding-left: 10px;
}

.sidebar-nav ul ul {
  background: url("../img/template/ie8_opacity_dark_40.png") repeat;
  background: rgba(0, 0, 0, 0.4);
}

.sidebar-nav ul ul a {
  padding-left: 25px;
}

.sidebar-nav ul ul a.active,
.sidebar-nav ul ul a.active:hover {
  padding-left: 20px;
}

/* Sidebar Header */
.sidebar-header {
  margin: 10px 0 0;
  padding: 10px;
  line-height: 12px;
}

.sidebar-header + .sidebar-section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.sidebar-header .sidebar-header-title {
  color: #ffffff;
  font-size: 11px;
  text-transform: uppercase;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.sidebar-header-options {
  float: right;
  display: inline-block;
}

.sidebar-header-options > a,
.sidebar-nav .sidebar-header-options a {
  float: right;
  margin: 0;
  padding: 0;
  min-height: 0;
  line-height: inherit;
  display: block;
  min-width: 18px;
  text-align: center;
  color: #ffffff;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.sidebar-header-options a.active,
.sidebar-header-options a:hover,
.sidebar-header-options a:focus,
.sidebar-nav .sidebar-header-options a.active,
.sidebar-nav .sidebar-header-options a:hover,
.sidebar-nav .sidebar-header-options a:focus {
  background: none;
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.sidebar-header-options a > i {
  font-size: 14px;
}

.sidebar-nav a.b2axis-link {
  background: #7dc419;
  color: #FFFFFF;
}

/*
 *  Document   : content
 *  Author     : bhW
 *
 *  MAIN CONTENT
 *
 */
/* Content Header */
.content-header {
  background-color: #ffffff;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #c6cbbf;
}

.content-header h1,
.content-header h2 {
  margin: 0;
  font-size: 26px;
  line-height: 32px;
}

.content-header small
.content-header small {
  font-size: 17px;
}

.header-section h1 i {
  font-size: 44px;
  float: right;
  margin: 2px 0 0 10px;
  color: #d8d8d8;
  margin: 0 0 0 10px;
  line-height: 32px;
}

.header-section {
  padding: 30px 10px;
}

.content-header,
.content-top {
  margin: -10px -5px 10px;
}

.content-top {
  background-color: #ffffff;
  border-bottom: 1px solid #c6cbbf;
}

.content-header-media {
  position: relative;
  height: 248px;
  overflow: hidden;
  border-top-color: #222222;
}

.content-header-media .header-section {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  background: url("../img/template/ie8_opacity_dark_60.png") repeat;
  background: rgba(0, 0, 0, 0.6);
}

.content-header-media small,
.content-header-media i {
  color: #dddddd;
}

.content-header-media > img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 2560px;
  height: 248px;
  margin-left: -1280px;
}

.content-header-media > .content-header-media-map {
  height: 270px;
}

/* Blocks */
.block {
  margin: 0 0 10px;
  padding: 15px 15px 1px;
  background-color: #ffffff;
  border: 1px solid #c6cbbf;
  border-radius: 0;
}

.block.full {
  padding: 20px 15px;
}

.block .block-content-full {
  margin: -20px -15px -1px;
}

.block .block-content-mini-padding {
  padding: 8px;
}

.block.full .block-content-full {
  margin: -20px -15px;
}

.block .tab-content .block-content-full,
.block.full .tab-content .block-content-full {
  margin-top: -19px !important;
}

.block-title {
  margin: -20px -15px 20px;
  background-color: #fafcfa;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.block-title h1,
.block-title h2,
.block-title h3,
.block-title h4,
.block-title h5,
.block-title h6 {
  display: inline-block;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  padding: 10px 16px 7px;
  font-weight: normal;
}

.block-title h1 small,
.block-title h2 small,
.block-title h3 small,
.block-title h4 small,
.block-title h5 small,
.block-title h6 small {
  font-size: 13px;
  color: #777777;
  font-weight: normal;
}

.block-title h1,
.block-title h2,
.block-title h3 {
  padding-left: 15px;
  padding-right: 15px;
}

.block-title .nav-tabs,
.block-options {
  min-height: 40px;
  line-height: 38px;
}

.block-title .nav-tabs {
  padding: 3px 1px 0;
  border-bottom: none;
}

.block-title .nav-tabs > li > a {
  border-bottom: none;
}

.block-title .nav-tabs {
  margin-bottom: -2px;
}

.block-title .nav-tabs > li > a {
  margin-bottom: 0;
}

.block-title .nav-tabs > li > a:hover {
  background: none;
}

.block-title .nav-tabs > li.active > a,
.block-title .nav-tabs > li.active > a:hover,
.block-title .nav-tabs > li.active > a:focus {
  border: 1px solid #d8d8d8;
  border-bottom-color: #ffffff;
  background-color: #ffffff;
}

.block-title .nav-tabs > li.input > a {
  border: 1px solid #c6cbbf;
  border-bottom-color: #ffffff;
  background-color: #7dc419;
  color: #ffffff;
}

.block-title code {
  padding: 2px 3px;
}

.block-options {
  margin: 0 6px;
  line-height: 37px;
}

.block-options .label {
  display: inline-block;
  padding: 6px;
  vertical-align: middle;
  font-size: 13px;
}

.block-top {
  margin: -20px -15px 20px;
  border-bottom: 1px dotted #c6cbbf;
}

.block-section {
  margin-bottom: 20px;
}

.block.block-fullscreen {
  position: fixed;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  z-index: 1031;
  margin-bottom: 0;
  overflow-y: auto;
}

/* Widgets */
.widget {
  background-color: #ffffff;
  margin-bottom: 10px;
}

.widget .widget-extra-full,
.widget .widget-extra {
  position: relative;
  padding: 15px;
}

.widget .widget-extra {
  padding-top: 1px;
  padding-bottom: 1px;
}

.widget .widget-content-light {
  color: #ffffff;
}

.widget .widget-content-light small {
  color: #eeeeee;
}

.widget .widget-image,
.widget .widget-icon {
  width: 64px;
  height: 64px;
}

.widget .widget-icon {
  display: inline-block;
  line-height: 64px;
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  border-radius: 32px;
}

.widget .widget-icon .gi,
.widget .widget-icon .si,
.widget .widget-icon .hi,
.widget .widget-icon .fi {
  margin-top: -3px;
}

.widget .widget-options,
.widget .widget-options-left {
  position: absolute;
  top: 5px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.widget .widget-options {
  right: 5px;
}

.widget .widget-options-left {
  left: 5px;
}

.widget .widget-options:hover,
.widget .widget-options-left:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.widget-simple {
  padding: 15px;
}

.widget-simple:before,
.widget-simple:after {
  content: " ";
  display: table;
}

.widget-simple:after {
  clear: both;
}

.widget-simple .widget-image,
.widget-simple .widget-icon {
  margin: 0 15px;
}

.widget-simple .widget-image.pull-left,
.widget-simple .widget-icon.pull-left {
  margin-left: 0;
}

.widget-simple .widget-image.pull-right,
.widget-simple .widget-icon.pull-right {
  margin-right: 0;
}

.widget-simple .widget-content {
  font-size: 18px;
  margin: 12px 0;
}

.widget-simple .widget-content small {
  display: block;
  margin-top: 7px;
  font-size: 13px;
  font-weight: 400;
}

.widget-advanced .widget-header {
  position: relative;
  padding: 15px 15px 50px;
  height: 110px;
  overflow: hidden;
}

.widget-advanced .widget-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 110px;
}

.widget-advanced .widget-background-map {
  height: 180px;
  width: 100%;
}

.widget-advanced .widget-content-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  margin: 0;
  background: url("../img/template/ie8_opacity_dark_60.png") repeat;
  background: rgba(0, 0, 0, 0.6);
}

.widget-advanced .widget-main {
  position: relative;
  padding: 10px 15px 15px;
}

.widget-advanced .widget-image-container {
  position: absolute;
  display: inline-block;
  padding: 5px;
  width: 74px;
  height: 74px;
  top: -36px;
  left: 50%;
  margin-left: -36px;
  border-radius: 36px;
  background-color: #ffffff;
}

.widget-advanced .widget-header .widget-image-container {
  position: static;
  left: auto;
  top: auto;
  margin: 0;
}

.widget-advanced-alt .widget-header,
.widget-advanced-alt .widget-main {
  padding: 15px;
}

.widget-advanced-alt .widget-header {
  height: auto;
  min-height: 150px;
}

/* Link Widgets */
a.widget {
  display: block;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

a.widget:hover,
a.widget:active {
  text-decoration: none;
}

a.widget.widget-hover-effect1:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

a.widget.widget-hover-effect1:active {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

a.widget.widget-hover-effect2:hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.2);
}

a.widget.widget-hover-effect2:active {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.widget.widget-hover-effect3:hover {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}

a.widget.widget-hover-effect3:active {
  -webkit-transform: rotate(2deg) scale(0.95);
  transform: rotate(2deg) scale(0.95);
}

a.widget.widget-hover-effect4:hover {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

a.widget.widget-hover-effect4:active {
  -webkit-transform: rotate(-1deg) scale(0.95);
  transform: rotate(-1deg) scale(0.95);
}

/* Content which contains floats */
.content-float .pull-left {
  margin: 0 20px 20px 0;
}

.content-float .pull-right {
  margin: 0 0 20px 20px;
}

/* Draggable Blocks */
.draggable-blocks .block-title {
  cursor: move;
}

.draggable-placeholder {
  background-color: #dddddd;
  border: 1px dashed #999999;
  margin-bottom: 10px;
}

/* Scroll to top link */
#to-top {
  display: none;
  position: fixed;
  bottom: 55px;
  left: 5px;
  border-radius: 3px;
  padding: 0 12px;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

#to-top:hover {
  color: #ffffff;
  background-color: #7dc419;
  text-decoration: none;
  opacity: 1;
  filter: alpha(opacity=100);
}

/* Claim: Damage Areas */
.damage-area-container {
  position: relative;
  margin-bottom: 20px;
}

.damage-area-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
}

#damage-areas .top-row, #damage-areas .middle-row, #damage-areas .bottom-row {
  display: table;
  height: 33.3333%;
  width: 100%;
}

.damage-area {
  width: 33.3333%;
  height: 33.3333%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  -webkit-transition: background 0.4s linear;
  /* For Safari 3.1 to 6.0 */
  transition: background 0.4s linear;
}

.damage-area .form-group {
  margin-bottom: 0;
}

.damage-area.checked {
  background: rgba(125, 196, 25, 0.3);
  -webkit-transition: background 0.4s linear;
  /* For Safari 3.1 to 6.0 */
  transition: background 0.4s linear;
}

/*
 *  Document   : pages
 *  Author     : bhWebDesigner
 *
 *  PAGES
 *  Styles needed only on specific pages
 *
 */
/* Login */
#login-background {
  width: 100%;
  height: 224px;
  overflow: hidden;
  position: relative;
}

#login-background > img {
  position: absolute;
  width: 2560px;
  height: 400px;
  left: 50%;
  margin-left: -1280px;
}

#login-alt-container {
  display: none;
}

#login-container,
#login-alt-container {
  position: absolute;
  width: 300px;
  top: 10px;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
}

#login-container .login-title {
  padding: 20px 10px;
  background: #FFFFFF;
  border-bottom: 1px dashed #d8d8d8;
}

#login-container .login-title h1,
#login-alt-container h1 {
  font-size: 26px;
  color: #7dc419;
  margin: 8px 0 3px;
}

#login-container .login-title h1 small,
#login-alt-container h1 small {
  font-size: 16px;
  color: #7dc419;
}

#login-container > .block {
  border: none;
}

#login-container .register-terms {
  line-height: 30px;
  margin-right: 10px;
  float: left;
}

/* Full Background Image */
img.full-bg {
  min-height: 100%;
  min-width: 1280px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1280px) {
  img.full-bg {
    left: 50%;
    margin-left: -640px;
  }
}

/* Full Page Container */
.full-page-container {
  position: absolute;
  width: 300px;
  padding: 10px;
  top: 20px;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
  background-color: #333333;
  background-color: rgba(0, 0, 0, 0.5);
}

.full-page-section {
  padding: 25px 0;
  margin: 25px -10px;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.15);
}

/* Countdown */
.countdown-con {
  color: #ffffff;
  text-align: center;
}

.countdown-con .countdown-num {
  font-size: 56px;
  font-weight: bold;
}

.countdown-con .countdown-info {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

/* Calendar */
.calendar-events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.calendar-events li {
  color: #ffffff;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #555555;
  opacity: 0.85;
  filter: alpha(opacity=85);
}

.calendar-events li:hover {
  cursor: move;
  opacity: 1;
  filter: alpha(opacity=100);
}

/* Gallery */
.gallery img, .gallery a img, .gallery-image img, a[data-toggle="lightbox-image"] img {
  max-width: 100%;
}

a[data-toggle="lightbox-image"],
a.gallery-link {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.gallery a:hover img,
.gallery-image:hover img,
a[data-toggle="lightbox-image"]:hover img {
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.gallery-image {
  position: relative;
}

.gallery-image-options {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  padding: 10px;
}

.gallery-image:hover .gallery-image-options {
  display: block;
}

.gallery > .row > div {
  margin-bottom: 15px;
}

.gallery.gallery-widget > .row > div {
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

/* Media Items */
.media-items {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  border: 2px solid #f1f1f1;
  -webkit-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

.media-items:hover {
  border-color: #cccccc;
}

.media-items:hover .media-items-options {
  display: block;
}

.media-items .media-items-options {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px;
  display: none;
}

.media-items .media-items-content {
  padding: 40px 0 20px;
}

/* Charts */
.pie-chart .pie-avatar {
  position: absolute;
  top: 8px;
  left: 8px;
}

.chart {
  height: 360px;
}

.chart-tooltip,
.mini-chart-tooltip {
  position: absolute;
  display: none;
  color: #ffffff;
  background-color: #000000;
  padding: 4px 10px;
}

.chart-pie-label {
  font-size: 12px;
  text-align: center;
  padding: 8px 12px;
  color: #ffffff;
}

.mini-chart-tooltip {
  left: 0;
  top: 0;
  visibility: hidden;
}

.legend table td {
  padding: 4px;
  font-size: 14px;
}

/* Timeline */
.timeline {
  position: relative;
}

.timeline-header {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 0 15px;
  min-height: 60px;
  line-height: 60px;
  background-color: #ffffff;
  border-bottom: 2px solid #f0f0f0;
  z-index: 500;
}

.timeline-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.timeline-list:after {
  position: absolute;
  display: block;
  width: 2px;
  top: 0;
  left: 95px;
  bottom: 0;
  content: "";
  background-color: #f0f0f0;
  z-index: 1;
}

.timeline-header + .timeline-list:after {
  top: 60px;
}

.timeline-list li {
  position: relative;
  margin: 0;
  padding: 15px 0;
}

.timeline-list.timeline-hover li:hover {
  background-color: #f9f9f9;
}

.timeline-list .timeline-icon {
  position: absolute;
  left: 80px;
  top: 10px;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 15px;
  z-index: 500;
}

.timeline-list .active .timeline-icon {
  background-color: #7dc419;
  border-color: #7dc419;
  color: #ffffff;
}

.timeline-list .timeline-time {
  float: left;
  width: 70px;
  text-align: right;
}

.timeline-list .timeline-content {
  margin-left: 120px;
}

.block-content-full .timeline-content {
  padding-right: 20px;
}

.media-feed {
  margin-bottom: 0;
}

.media-feed > .media {
  margin-top: 0;
  padding: 20px 20px 0;
  border-top: 1px dotted #c6cbbf;
}

.media-feed > .media:first-child {
  border-top: none;
}

.media-feed.media-feed-hover > .media:hover {
  background-color: #f9f9f9;
}

/* Error */
#error-container {
  padding: 120px 20px;
  position: relative;
}

#error-container .error-options {
  position: absolute;
  top: 20px;
  left: 20px;
}

#error-container h1 {
  font-size: 96px;
  color: #ffffff;
  margin-bottom: 40px;
}

#error-container h1 i {
  color: #7dc419;
}

#error-container h2 {
  color: #cccccc;
  margin-bottom: 40px;
  line-height: 1.4;
}

#error-container form {
  padding: 20px;
  border-radius: 3px;
  background: #ffffff;
  background: url("../img/template/ie8_opacity_light_10.png") repeat;
  background: rgba(255, 255, 255, 0.1);
}

#error-container .form-control {
  border-color: #ffffff;
}

/* Pricing Table */
.table.table-pricing {
  background-color: #ffffff;
}

.table-pricing th,
.table-pricing td {
  text-align: center;
}

.table-pricing th {
  font-size: 24px !important;
}

.table-pricing td {
  font-size: 15px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.table-pricing .table-price {
  background-color: #f9f9f9;
}

.table-pricing.table-featured .table-price,
.table-pricing .table-price.table-featured {
  background-color: #252525;
}

.table-pricing.table-featured th,
.table-pricing th.table-featured {
  background-color: #7dc419;
  border-bottom: 2px solid #232824;
  color: #ffffff;
}

.table-pricing.table-featured td,
.table-pricing td.table-featured {
  background-color: #232824;
  color: #ffffff;
}

/* Chat UI */
.chatui-container {
  position: relative;
}

.chatui-people,
.chatui-talk {
  overflow-y: hidden;
}

.chatui-people .chatui-header {
  font-size: 14px;
  line-height: 14px;
  color: #999999;
  text-transform: uppercase;
  padding: 5px 20px;
}

.chatui-people hr {
  border-top-color: #252525;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.chatui-people .list-group-item {
  background: transparent;
  min-height: 52px;
  border: 0;
  border-radius: 0 !important;
}

.chatui-people .list-group-item .badge {
  margin-top: 7px;
}

.chatui-people .list-group-item .list-group-item-heading {
  color: #ffffff;
  line-height: 32px;
  margin: 0;
}

.chatui-people .list-group-item:hover {
  background: url("../img/template/ie8_opacity_dark_20.png") repeat;
  background: rgba(0, 0, 0, 0.2);
}

.chatui-people .list-group-item img {
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.chatui-talk {
  top: 0;
  bottom: 50px;
}

.chatui-talk ul {
  padding: 15px;
  margin: 0;
  list-style: none;
}

.chatui-talk li {
  padding: 5px 10px;
  margin-bottom: 7px;
}

.chatui-talk .chatui-talk-msg {
  padding: 15px 10px;
  padding-right: 50px;
  border-left: 5px solid #333333;
  background-color: #f6f6f6;
  position: relative;
}

.chatui-talk .chatui-talk-msg.chatui-talk-msg-highlight {
  padding-left: 50px;
  border-left: none;
  border-right-width: 5px;
  border-right-style: solid;
}

.chatui-talk-msg .chatui-talk-msg-avatar {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
}

.chatui-talk-msg.chatui-talk-msg-highlight .chatui-talk-msg-avatar {
  top: 8px;
  right: auto;
  left: 8px;
}

.chatui-input {
  height: 50px;
  line-height: 50px;
  border-top: 1px solid #eeeeee;
}

/* Tasks */
.task-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.task-list li {
  padding: 15px 40px 15px 15px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;
  -webkit-transition: box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out;
}

.task-list li:hover {
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.task-list li.in-1x {
  margin-left: 15px;
}

.task-list li.in-2x {
  margin-left: 30px;
}

.task-list li > .task-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #000000;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

.task-list li:hover > .task-close {
  opacity: .25;
  filter: alpha(opacity=25);
}

.task-list li.task-done {
  opacity: .50;
  filter: alpha(opacity=50);
}

.task-list li.task-done > label {
  text-decoration: line-through;
}

th.sortable {
  cursor: pointer;
  cursor: hand;
}

/* Live Dashboard */
#live-dashboard {
  padding: 60px 20px;
  position: relative;
}

#live-dashboard .live-dashboard-options {
  position: absolute;
  top: 20px;
  left: 20px;
}

#live-dashboard .live-dashboard-options a {
  color: #999999;
}

#live-dashboard .live-dashboard-logo {
  margin-bottom: 40px;
}

#live-dashboard .live-dashboard-updated {
  color: #fff;
  margin-top: 40px;
}

#live-dashboard .erac-background {
  background: #009750;
}

#live-dashboard .qbps-background {
  background: #002e5b;
}

/*
 *  Document   : bs_overwrite
 *  Author     : bhWebDesigner
 *
 *  BOOTSTRAP OVERWRITE/EXTEND STYLES
 *
 */
/* Navbar */
.navbar.navbar-default {
  background-color: #fafcfa;
}

.navbar.navbar-inverse {
  background-color: #232824;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  border-width: 0;
}

/* Typography */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small,
h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small,
.h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: 300;
  color: #777777;
}

h1, h2, h3 {
  margin-bottom: 15px;
}

.text-primary,
.text-primary:hover,
a,
a:hover,
a:focus,
a.text-primary,
a.text-primary:hover,
a.text-primary:focus {
  color: #7dc419;
}

.text-danger,
.text-danger:hover,
a.text-danger,
a.text-danger:hover,
a.text-danger:focus {
  color: #e74c3c;
}

.text-warning,
.text-warning:hover,
a.text-warning,
a.text-warning:hover,
a.text-warning:focus {
  color: #e67e22;
}

.text-success,
.text-success:hover,
a.text-success,
a.text-success:hover,
a.text-success:focus {
  color: #27ae60;
}

.text-info,
.text-info:hover,
a.text-info,
a.text-info:hover,
a.text-info:focus {
  color: #3498db;
}

.text-muted,
.text-muted:hover,
a.text-muted,
a.text-muted:hover,
a.text-muted:focus {
  color: #999999;
}

.text-light,
.text-light:hover,
a.text-light,
a.text-light:hover,
a.text-light:focus {
  color: #ffffff;
}

b, strong {
  font-weight: 600;
}

ul,
ol {
  padding-left: 30px;
}

.list-li-push li {
  margin-bottom: 10px;
}

p {
  line-height: 1.6;
}

article p {
  font-size: 16px;
  line-height: 1.8;
}

.well {
  background-color: #f9f9f9;
  border: 1px solid #eeeeee;
}

.page-header {
  border-bottom-width: 1px;
  border-bottom-color: #dddddd;
  margin: 30px 0 20px;
}

.sub-header {
  margin: 10px 0 20px;
  padding: 10px 0;
  border-bottom: 1px dotted #dddddd;
}

blockquote {
  border-left-width: 3px;
}

blockquote {
  margin: 20px 0;
  padding: 30px 60px 30px 20px;
  position: relative;
  width: 100%;
  border-color: #d8d8d8;
}

blockquote:before {
  display: block;
  content: "\201C";
  font-family: serif;
  font-size: 96px;
  position: absolute;
  right: 10px;
  top: -30px;
  color: #d8d8d8;
}

blockquote.pull-right:before {
  left: 10px;
  right: auto;
}

/* Forms */
label {
  font-weight: 600;
}

fieldset legend {
  font-size: 16px;
  padding: 30px 0 10px;
  border-bottom: 2px solid #d8d8d8;
}

input[type="file"] {
  padding-top: 7px;
}

input[type="text"].form-control,
input[type="password"].form-control,
input[type="email"].form-control,
textarea.form-control {
  -webkit-appearance: none;
}

.form-control {
  font-size: 13px;
  padding: 6px 8px;
  max-width: 100%;
  margin: 1px 0;
  color: #232824;
  border-color: #c6cbbf;
}

.form-control-borderless .form-control,
.form-control-borderless .input-group-addon,
.form-control-borderless,
.form-control-borderless:focus {
  border: transparent !important;
}

.input-group {
  margin-top: 1px;
  margin-bottom: 1px;
}

.input-group .form-control {
  margin-top: 0;
}

.form-control:focus {
  border-color: #7dc419;
}

.help-block {
  color: #777777;
  font-weight: 400;
}

.input-group-addon {
  min-width: 45px;
  text-align: center;
  background-color: #ffffff;
  border-color: #c6cbbf;
}

.form-horizontal .control-label {
  margin-bottom: 5px;
}

.form-bordered {
  margin: -20px -15px -1px;
}

.modal-body .form-bordered {
  margin-bottom: -20px;
}

.form-bordered fieldset legend {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.form-bordered .form-group {
  margin: 0;
  border: none;
  padding: 15px;
  border-bottom: 1px dashed #d8d8d8;
}

.form-bordered .form-group.form-actions {
  background-color: #fafcfa;
  border-bottom: none;
  padding-top: 15px;
  margin-top: -10px;
}

.form-horizontal.form-bordered .form-group {
  padding-left: 0;
  padding-right: 0;
}

.form-bordered .help-block {
  margin-bottom: 0;
}

.form-totals {
  background-color: #fafcfa;
  border-top: 1px dashed #d8d8d8;
  border-bottom: none;
  padding-top: 15px;
  margin-left: -20px !important;
  margin-right: -20px !important;
  margin-bottom: 0px !important;
}

.form-totals.bottom-bdr {
  border-bottom: 1px dashed #d8d8d8;
  margin-bottom: 20px !important;
}

.has-success .form-control,
.has-warning .form-control,
.has-error .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-success .help-block,
.has-success .control-label,
.has-success .input-group-addon,
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .radio,
.has-success .radio-inline {
  color: #27ae60;
}

.has-success .form-control,
.has-success .input-group-addon {
  border-color: #27ae60;
  background-color: #ffffff;
}

.has-success .form-control:focus {
  border-color: #166638;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .input-group-addon,
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .radio,
.has-warning .radio-inline {
  color: #e67e22;
}

.has-warning .form-control,
.has-warning .input-group-addon {
  border-color: #e67e22;
  background-color: #ffffff;
}

.has-warning .form-control:focus {
  border-color: #b3621b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .input-group-addon,
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .radio,
.has-error .radio-inline {
  color: #e74c3c;
}

.has-error .form-control,
.has-error .input-group-addon {
  border-color: #e74c3c;
  background-color: #ffffff;
}

.has-error .form-control:focus {
  border-color: #c0392b;
}

/* Form Wizards */
.wizard-steps {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
}

.form-bordered .wizard-steps {
  margin-bottom: 0;
}

.wizard-steps .row {
  margin: 0;
}

.wizard-steps .row div {
  padding: 15px 0;
  font-size: 15px;
  text-align: center;
}

.form-bordered .wizard-steps .row div {
  padding-top: 10px;
}

.wizard-steps span {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 1px solid #7dc419;
  border-radius: 50px;
}

.wizard-steps div.done span,
.wizard-steps div.active span {
  background-color: #7dc419;
  color: #ffffff;
}

.wizard-steps div.done span {
  opacity: 0.25;
  filter: alpha(opacity=25);
}

.wizard-steps div.active span {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* Form Select Switches */
.switch {
  margin: 1px 0;
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}

.switch span {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  border-radius: 28px;
  background-color: #f9f9f9;
  border: 1px solid #dddddd;
  -webkit-transition: background-color 0.35s;
  transition: background-color 0.35s;
}

.switch span:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 1px;
  bottom: 1px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 50%;
  -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.switch input:checked + span:after {
  left: 26px;
  border: none;
  -webkit-box-shadow: -2px 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.1);
}

.switch input:checked + span {
  background-color: #eeeeee;
}

.switch-default span {
  border-color: #c6cbbf;
}

.switch-default input:checked + span {
  background-color: #c6cbbf;
}

.switch-primary span {
  border-color: #7dc419;
}

.switch-primary input:checked + span {
  background-color: #7dc419;
}

.switch-info span {
  border-color: #7abce7;
}

.switch-info input:checked + span {
  background-color: #7abce7;
}

.switch-success span {
  border-color: #aad178;
}

.switch-success input:checked + span {
  background-color: #aad178;
}

.switch-warning span {
  border-color: #f7be64;
}

.switch-warning input:checked + span {
  background-color: #f7be64;
}

.switch-danger span {
  border-color: #ef8a80;
}

.switch-danger input:checked + span {
  background-color: #ef8a80;
}

.lt-ie9 .switch input {
  position: static;
  opacity: 1;
  filter: alpha(opacity=100);
}

.lt-ie9 .switch span,
.lt-ie9 .switch span:after {
  display: none !important;
}

/* Tables */
.table.table-vcenter th,
.table.table-vcenter td {
  vertical-align: middle;
}

.table-options {
  padding: 6px 0;
}

.table thead > tr > th {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.table thead > tr > th > small {
  font-weight: 400;
  font-size: 75%;
}

.table thead > tr > th,
.table thead > tr > td,
.table tfoot > tr > th,
.table tfoot > tr > td {
  padding-top: 14px;
  padding-bottom: 14px;
}

.table tfoot > tr > th,
.table tfoot > tr > td {
  background-color: #fafcfa;
}

.table-borderless tbody > tr > th,
.table-borderless tbody > tr > td {
  border-top-width: 0;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td,
.table tbody + tbody,
.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border-color: #d8d8d8;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #d8d8d8;
}

/* List Group */
.list-group-item {
  border-color: #d8d8d8;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: #7dc419;
  border-color: #7dc419;
}

a.list-group-item.active .list-group-item-text,
a.list-group-item.active:hover .list-group-item-text,
a.list-group-item.active:focus .list-group-item-text {
  color: #ffffff;
}

a.list-group-item:hover,
a.list-group-item:focus {
  background-color: #fafcfa;
}

a.list-group-item.active > .badge {
  background: url("../img/template/ie8_opacity_dark_40.png") repeat;
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

/* Navs */
.nav-pills > li > a {
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 6px;
  background: #232824;
  color: #d8d8d8;
}

.nav-pills > li {
  padding-right: 5px;
}

.nav-pills > li:nth-last-child(1) {
  padding-right: 0;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  color: #fff;
  background-color: #7dc419;
}

.nav-pills > li.has-error > a,
.nav-pills > li.has-error > a:hover,
.nav-pills > li.has-error > a:focus,
.nav-pills > li.has-error > a:active {
  background: #E74C3C;
  color: #FFFFFF;
}

.nav > li i {
  font-size: 14px;
}

.nav-pills > .active > a > .badge {
  color: #7dc419;
}

.nav-pills.nav-icons > li > a i {
  margin-right: 10px;
}

.nav-pills.nav-icons > li.active > a i,
.nav-pills.nav-icons > li.active > a:hover i,
.nav-pills.nav-icons > li.active > a:focus i {
  color: #ffffff;
}

.nav-stacked > li > a {
  margin: 4px 0 0;
}

.nav .caret,
.nav a:hover .caret,
.nav a:focus .caret {
  border-top-color: #7dc419;
  border-bottom-color: #7dc419;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #7dc419;
  color: #FFFFFF;
}

.nav-tabs {
  border-bottom-color: #d8d8d8;
}

.nav-tabs > li {
  margin-bottom: 0;
}

.nav-tabs > li > a {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: -1px;
}

.nav-tabs > li > a:hover {
  border-color: #d8d8d8;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #232824;
  border-color: #d8d8d8;
  border-bottom-color: transparent;
}

.nav-pills > li.active > a > .badge {
  background: url("../img/template/ie8_opacity_dark_20.png") repeat;
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.dropdown-menu {
  padding: 0;
  font-size: 13px;
  border-color: #c6cbbf;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-menu > li > a {
  padding: 6px 10px;
}

.dropdown-menu i {
  opacity: 0.2;
  filter: alpha(opacity=20);
  line-height: 17px;
}

.dropdown-menu a:hover i {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.dropdown-menu .divider {
  margin: 2px 0;
  padding: 0 !important;
  background-color: #f0f0f0;
}

li.dropdown-header {
  padding: 5px 10px;
  color: #232824;
  background-color: #fafcfa;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.dropdown-menu li:first-child.dropdown-header {
  border-top: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.dropdown-menu.dropdown-custom {
  min-width: 200px;
}

.dropdown-menu.dropdown-custom > li {
  padding: 8px 10px;
  font-size: 12px;
}

.dropdown-menu.dropdown-custom > li > a {
  border-radius: 3px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -3px;
  margin-left: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.collapse.in .dropdown.open .dropdown-menu {
  display: block;
  position: static;
  margin: 0 0 0 15px;
  left: auto;
  top: auto;
}

.navbar-fixed-top .dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.pagination > li > a,
.pagination > li > span {
  color: #7dc419;
  margin-left: 5px;
  margin-right: 5px;
  border: none !important;
  border-radius: 25px !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #7dc419;
}

.pager > li > a,
.pager > li > span {
  border-color: #d8d8d8;
}

.pager > li > a:hover,
.pagination > li > a:hover {
  background-color: #7dc419;
  border-color: #7dc419;
  color: #ffffff;
}

.pager > li.disabled > a:hover {
  border-color: #d8d8d8;
}

/* Horizontal Navigation */
.nav-horizontal {
  padding: 10px 7px 1px;
  margin: 0;
  list-style: none;
}

.nav-horizontal li {
  display: inline-block;
  margin: 0 3px 9px;
}

.nav-horizontal a {
  display: block;
  min-width: 140px;
  border-radius: 3px;
  font-weight: bold;
  text-align: left;
  padding: 8px 10px;
  background-color: #fafcfa;
}

.nav-horizontal a:hover,
.nav-horizontal li.active a {
  background-color: #7dc419;
  text-decoration: none;
  color: #ffffff;
}

.nav-horizontal a:focus {
  text-decoration: none;
}

.nav-horizontal a:hover i,
.nav-horizontal li.active a i {
  color: #ffffff;
  opacity: .6;
  filter: alpha(opacity=60);
}

.nav-horizontal i {
  display: inline-block;
  font-size: 14px;
  color: #c6cbbf;
  margin-right: 5px;
}

/* Popover */
.popover-title {
  background: none;
  border: none;
  font-size: 17px;
  font-weight: 600;
}

/* Tooltip */
.tooltip {
  z-index: 1051;
}

.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}

.tooltip-inner {
  padding: 4px 6px;
  background-color: #000000;
  color: #ffffff;
}

.tooltip.top .tooltip-arrow,
.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
  border-top-color: #000000;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #000000;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #000000;
}

.tooltip.bottom .tooltip-arrow,
.tooltip.bottom-left .tooltip-arrow,
.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #000000;
}

/* Breadcrumps */
.breadcrumb {
  background-color: #ffffff;
}

.breadcrumb i {
  font-size: 14px;
}

.breadcrumb-top {
  margin: -10px -5px 10px;
  padding: 7px 10px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #c6cbbf;
  font-size: 12px;
}

.content-header + .breadcrumb-top,
.breadcrumb-top + .content-header {
  margin-top: -11px;
}

.breadcrumb > li + li:before {
  content: "\203a";
}

/* Progress Bars */
.progress,
.progress-bar {
  height: 20px;
  line-height: 20px;
}

.progress-bar-danger {
  background-color: #e74c3c;
}

.progress-bar-warning {
  background-color: #f39c12;
}

.progress-bar-success {
  background-color: #2ecc71;
}

.progress-bar-info {
  background-color: #3498db;
}

/* Modals */
.modal-content {
  border-radius: 3px;
}

.modal-header {
  padding: 15px 15px 14px;
  border-bottom: 1px solid #eeeeee;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-title {
  font-weight: 300;
}

.modal-body {
  padding: 20px 15px;
}

.modal-body .nav-tabs {
  margin: 0 -15px 15px;
  padding: 0 5px !important;
}

.modal-footer {
  margin-top: 0;
  padding: 14px 15px 15px;
  border-top: 1px solid #eeeeee;
  background-color: #f9f9f9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Buttons */
.btn {
  margin: 1px 0;
  background-color: #ffffff;
}

.btn .gi,
.btn .hi,
.btn .si,
.btn .fi {
  line-height: 1;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.block-options .btn,
.input-group .btn,
.modal-content .btn {
  margin-top: 0;
  margin-bottom: 0;
}

.btn-default {
  background-color: #f1f4ed;
  border-color: #c6cbbf;
  color: #232824;
}

.btn-default.btn-alt {
  background-color: #ffffff;
}

.btn-default:hover {
  background-color: #d8d8d8;
  border-color: #d3e0c1;
}

.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .btn-default.dropdown-toggle {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.btn-primary {
  background-color: #8bc13e;
  border-color: #7dc419;
  color: #ffffff;
}

.btn-primary.btn-alt {
  background-color: #ffffff;
  color: #7dc419;
}

.btn-primary:hover {
  background-color: #7dc419;
  border-color: #689726;
  color: #ffffff;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .btn-primary.dropdown-toggle {
  background-color: #7dc419;
  border-color: #7dc419;
  color: #ffffff;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #7dc419;
  border-color: #7dc419;
  color: #ffffff;
}

.btn-danger {
  background-color: #ef8a80;
  border-color: #e74c3c;
  color: #ffffff;
}

.btn-danger.btn-alt {
  background-color: #ffffff;
  color: #e74c3c;
}

.btn-danger:hover {
  background-color: #e74c3c;
  border-color: #9c3428;
  color: #ffffff;
}

.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .btn-danger.dropdown-toggle {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: #ffffff;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: #ffffff;
}

.btn-warning {
  background-color: #f7be64;
  border-color: #f39c12;
  color: #ffffff;
}

.btn-warning.btn-alt {
  background-color: #ffffff;
  color: #f39c12;
}

.btn-warning:hover {
  background-color: #f39c12;
  border-color: #b3730c;
  color: #ffffff;
}

.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .btn-warning.dropdown-toggle {
  background-color: #f39c12;
  border-color: #f39c12;
  color: #ffffff;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #f39c12;
  border-color: #f39c12;
  color: #ffffff;
}

.btn-success {
  background-color: #aad178;
  border-color: #7db831;
  color: #ffffff;
}

.btn-success.btn-alt {
  background-color: #ffffff;
  color: #7db831;
}

.btn-success:hover {
  background-color: #7db831;
  border-color: #578022;
  color: #ffffff;
}

.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .btn-success.dropdown-toggle {
  background-color: #7db831;
  border-color: #7db831;
  color: #ffffff;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #7db831;
  border-color: #7db831;
  color: #ffffff;
}

.btn-info {
  background-color: #7abce7;
  border-color: #3498db;
  color: #ffffff;
}

.btn-info.btn-alt {
  background-color: #ffffff;
  color: #3498db;
}

.btn-info:hover {
  background-color: #3498db;
  border-color: #2875a8;
  color: #ffffff;
}

.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .btn-info.dropdown-toggle {
  background-color: #3498db;
  border-color: #3498db;
  color: #ffffff;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #3498db;
  border-color: #3498db;
  color: #ffffff;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link.btn-icon:hover,
.btn-link.btn-icon:focus {
  color: #7dc419;
}

.btn-link.btn-icon {
  color: #999999;
}

.btn-link.btn-icon:hover,
.btn-link.btn-icon:focus {
  text-decoration: none;
}

.block-options .btn {
  border-radius: 15px;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 30px;
  text-align: center;
}

/* Panels */
.panel {
  margin-bottom: 20px;
}

.panel-heading {
  padding: 15px;
}

.panel-title {
  font-size: 14px;
}

.panel-default > .panel-heading {
  background-color: #f9f9f9;
}

.panel-group {
  margin-bottom: 20px;
}

/* Pre, Code */
pre {
  background: #151515;
  overflow: scroll;
}

code {
  border: 1px solid #fad4df;
  margin: 1px 0;
  display: inline-block;
}

.btn code {
  display: inline;
  margin: 0;
}

/* Alerts */
.alert {
  border-top-width: 0;
  border-right-width: 2px;
  border-bottom-width: 0;
  border-left-width: 2px;
}

.alert-danger {
  color: #e74c3c;
  background-color: #ffd1cc;
  border-color: #ffb8b0;
}

.alert-danger .alert-link {
  color: #e74c3c;
}

.alert-warning {
  color: #e67e22;
  background-color: #ffe4cc;
  border-color: #ffd6b2;
}

.alert-warning .alert-link {
  color: #e67e22;
}

.alert-success {
  color: #27ae60;
  background-color: #daf2e4;
  border-color: #b8e5cb;
}

.alert-success .alert-link {
  color: #27ae60;
}

.alert-info {
  color: #3498db;
  background-color: #dae8f2;
  border-color: #b8d2e5;
}

.alert-info .alert-link {
  color: #3498db;
}

.alert-dismissable .close {
  top: -5px;
  right: -25px;
}

.close {
  text-shadow: none;
}

/* Alternative Alerts */
.alert.alert-alt {
  margin: 0 0 2px;
  padding: 5px;
  font-size: 12px;
  border-width: 0;
  border-left-width: 2px;
}

.alert.alert-alt small {
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.alert-alt.alert-dismissable .close {
  right: 0;
}

.alert-alt.alert-dismissable .close:hover {
  color: #ffffff;
}

.alert-danger.alert-alt {
  border-color: #e74c3c;
}

.alert-warning.alert-alt {
  border-color: #e67e22;
}

.alert-success.alert-alt {
  border-color: #27ae60;
}

.alert-info.alert-alt {
  border-color: #3498db;
}

.sidebar-content .alert.alert-alt {
  margin-left: -10px;
  padding-left: 10px;
  background: transparent;
  color: #ffffff;
}

#sidebar-alt .sidebar-content .alert.alert-alt {
  margin-left: 0;
  margin-right: -10px;
  padding-left: 0;
  padding-right: 10px;
}

#sidebar-alt .sidebar-content .alert.alert-alt {
  border-width: 0;
  border-right-width: 2px;
}

/* Labels, Badges */
.label,
.badge {
  font-weight: normal;
  font-size: 90%;
}

.label {
  padding: 1px 4px;
}

.badge {
  background: url("../img/template/ie8_opacity_dark_30.png") repeat;
  background: rgba(0, 0, 0, 0.3);
  padding: 3px 6px;
}

.claim-notes .badge {
  font-size: 12px;
  vertical-align: middle;
}

.label-danger {
  background-color: #e74c3c;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #ff5542;
}

.label-warning {
  background-color: #e67e22;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ff8b26;
}

.label-success {
  background-color: #27ae60;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #2cc76c;
}

.label-info {
  background-color: #2980b9;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #2f92d4;
}

.label-primary {
  background-color: #7dc419;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #5ac5e0;
}

.label-default {
  background-color: #999999;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #777777;
}

/* Carousel */
.carousel-control.left,
.carousel-control.right,
.carousel-control.left.no-hover:hover,
.carousel-control.right.no-hover:hover {
  background: none;
}

.carousel-control.left:hover,
.carousel-control.right:hover {
  background: url("../img/template/ie8_opacity_dark_30.png") repeat;
  background: rgba(0, 0, 0, 0.3);
}

.carousel-control span {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
}

.carousel-control i {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}

/* Bottom Margin */
p, .table, .alert, .carousel {
  margin-bottom: 20px;
}

/* Removing shadows and radius */
.navbar-form-custom .form-control:hover,
.navbar-form-custom .form-control:focus,
.navbar-form,
.navbar-collapse,
.form-control,
.form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus,
.popover,
.progress,
.progress-bar,
.btn.active,
.open .btn.dropdown-toggle,
.panel {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-form-custom .form-control,
.navbar,
.dropdown-menu,
.tooltip-inner,
.breadcrumb,
.alert.alert-alt {
  border-radius: 0;
}

/* Claim Notes */
.claim-note .badge {
  font-size: 12px;
  font-weight: 300;
  vertical-align: middle;
}

/*
 *  Document   : extras
 *  Author     : bhWebDesigner
 *
 *  EXTRAS
 *  Helper, themes, responsive, retina and print related styles
 *
 */
/*
=================================================================
HELPERS
=================================================================
*/
.push-bit {
  margin-bottom: 10px !important;
}

.push {
  margin-bottom: 15px !important;
}

.push-top-bottom {
  margin-top: 40px;
  margin-bottom: 40px;
}

.display-none {
  display: none;
}

.remove-bottom-border {
  border-bottom: none !important;
}

.remove-margin-top {
  margin-top: 0 !important;
}

.remove-margin-bottom {
  margin-bottom: 0 !important;
}

.remove-margin-bottom {
  margin-bottom: 0 !important;
}

.remove-padding {
  padding: 0 !important;
}

.remove-radius {
  border-radius: 0 !important;
}

.remove-box-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.remove-transition {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

.lt-ie9 .hidden-lt-ie9,
.lt-ie10 .hidden-lt-ie10 {
  display: none !important;
}

.visible-lt-ie9,
.visible-lt-ie10 {
  display: none;
}

.lt-ie9 .visible-lt-ie9,
.lt-ie10 .visible-lt-ie10 {
  display: block;
}

:focus {
  outline: 0 !important;
}

/*
=================================================================
(#m08ths) THEMES
=================================================================
*/
/* Alternative Main Style */
.style-alt #page-content {
  background-color: #ffffff;
}

.style-alt .block {
  border-color: #c6cbbf;
}

.style-alt .block.block-alt-noborder {
  border-color: transparent;
}

.style-alt .block-title {
  background-color: #c6cbbf;
  border-bottom-color: #c6cbbf;
}

.style-alt .content-header + .breadcrumb-top,
.style-alt .breadcrumb-top + .content-header,
.style-alt #page-content + footer {
  background-color: #fafcfa;
}

.style-alt .content-header,
.style-alt .breadcrumb-top {
  border-bottom-color: #d8d8d8;
}

.style-alt #page-content + footer {
  border-top-color: #d8d8d8;
}

.style-alt .widget,
.style-alt .task-list li {
  background-color: #f6f6f6;
}

/* Test Circle used in Color Themes Page */
.test-circle {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 50px;
  background-color: #eeeeee;
  border: 2px solid #cccccc;
  color: #ffffff;
  margin-bottom: 15px;
}

/* Default Color Theme specific colors */
.themed-color {
  color: #7dc419;
}

.themed-border {
  border-color: #7dc419;
}

.themed-background {
  background-color: #7dc419;
}

.themed-color-dark {
  color: #232824;
}

.themed-border-dark {
  border-color: #232824;
}

.themed-background-dark {
  background-color: #232824;
}

/* Contextual Background Colors */
.themed-background-danger {
  background-color: #e74c3c !important;
}

.themed-background-warning {
  background-color: #e67e22 !important;
}

.themed-background-info {
  background-color: #3498db !important;
}

.themed-background-success {
  background-color: #27ae60 !important;
}

.themed-background-muted {
  background-color: #999999 !important;
}

.themed-background-muted-light {
  background-color: #f9f9f9 !important;
}

.themed-enterprise {
  background-color: #231F20;
}

.themed-quindell {
  background-color: #002E5B;
}

/*
=================================================================
RESPONSIVE
=================================================================
*/
/* Small devices, Tablets (>767px) */
@media screen and (min-width: 768px) {
  /* General */
  #login-background {
    height: 400px;
  }
  #login-background > img {
    top: 0;
  }
  #login-container,
  #login-alt-container {
    width: 480px;
    top: 186px;
    margin-left: -240px;
  }
  .full-page-container {
    width: 740px;
    top: 120px;
    padding: 20px;
    margin-left: -370px;
  }
  .full-page-section {
    padding: 50px 0;
    margin: 50px -20px;
  }
  #page-content {
    padding: 20px 20px 1px;
  }
  .header-section,
  .breadcrumb-top,
  .block,
  .block.full,
  .modal-body,
  #page-content + footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .block .block-content-full {
    margin: -20px -20px -1px;
  }
  .block.full .block-content-full {
    margin: -20px;
  }
  .content-header,
  .content-top,
  .breadcrumb-top {
    margin: -20px -20px 20px;
  }
  .content-header + .breadcrumb-top,
  .breadcrumb-top + .content-header {
    margin-top: -21px;
  }
  .block,
  .widget {
    margin-bottom: 20px;
  }
  .block-title,
  .block-top {
    margin-left: -20px;
    margin-right: -20px;
  }
  .draggable-placeholder {
    margin-bottom: 20px;
  }
  .navbar-fixed-top .dropdown-menu {
    max-height: none;
    overflow-y: visible;
  }
  /* Forms */
  .form-bordered {
    margin-left: -20px;
    margin-right: -20px;
  }
  .form-bordered .form-group {
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-horizontal.form-bordered .form-group {
    padding-left: 5px;
    padding-right: 5px;
  }
  /* Tabs */
  .nav-tabs > li > a {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 3px;
    margin-right: 3px;
  }
  /* Chat UI */
  .chatui-people,
  .chatui-talk,
  .chatui-input {
    position: absolute;
  }
  .chatui-talk,
  .chatui-input {
    right: 250px;
    left: 0;
  }
  .chatui-people {
    top: 0;
    right: 0;
    bottom: 0;
    width: 250px;
    height: auto;
  }
  .chatui-talk {
    height: auto;
  }
  .chatui-talk .chatui-talk-msg {
    width: 50%;
    margin-left: 50%;
  }
  .chatui-talk .chatui-talk-msg.chatui-talk-msg-highlight {
    margin-left: 0;
    margin-right: 50%;
  }
  .chatui-input {
    bottom: 0;
  }
}

/* Medium devices, Desktops (>991px) */
@media screen and (min-width: 992px) {
  /* General */
  #login-alt-container {
    display: block;
  }
  /* Sidebar Animations */
  .sidebar-no-animations #sidebar,
  .sidebar-no-animations #sidebar-alt {
    -webkit-transition: opacity 0.5s linear, background-color 0.2s ease-out;
    transition: opacity 0.5s linear, background-color 0.2s ease-out;
  }
  .sidebar-no-animations #main-container,
  .sidebar-no-animations.header-fixed-top header.navbar-fixed-top,
  .sidebar-no-animations.header-fixed-bottom header.navbar-fixed-bottom,
  .sidebar-no-animations.footer-fixed #page-content + footer {
    -webkit-transition: none;
    transition: none;
  }
  /* Reset sidebar classes used only on small screens */
  .sidebar-visible-xs #sidebar,
  .sidebar-alt-visible-xs #sidebar-alt {
    width: 0;
  }
  .sidebar-visible-xs #main-container,
  .sidebar-alt-visible-xs #main-container {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar-visible-xs.header-fixed-top header.navbar-fixed-top,
  .sidebar-visible-xs.header-fixed-bottom header.navbar-fixed-bottom,
  .sidebar-alt-visible-xs.header-fixed-top header.navbar-fixed-top,
  .sidebar-alt-visible-xs.header-fixed-bottom header.navbar-fixed-bottom,
  .sidebar-visible-xs.footer-fixed #page-content + footer,
  .sidebar-alt-visible-xs.footer-fixed #page-content + footer {
    right: 0;
    left: 0;
  }
  /* Both Sidebars */
  #sidebar,
  #sidebar-alt,
  .sidebar-partial #sidebar,
  .sidebar-alt-partial #sidebar-alt {
    width: 0;
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
  .sidebar-partial #sidebar,
  .sidebar-alt-partial #sidebar-alt {
    width: 65px;
  }
  .sidebar-partial #sidebar:hover,
  .sidebar-visible-lg #sidebar,
  .sidebar-visible-lg.sidebar-partial #sidebar,
  .sidebar-alt-partial #sidebar-alt:hover,
  .sidebar-alt-visible-lg #sidebar-alt,
  .sidebar-alt-visible-lg.sidebar-alt-partial #sidebar-alt {
    width: 200px;
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .sidebar-partial #sidebar .sidebar-brand i,
  .sidebar-alt-partial #sidebar-alt .sidebar-brand i {
    display: none;
  }
  .sidebar-partial #sidebar:hover .sidebar-brand i,
  .sidebar-visible-lg #sidebar .sidebar-brand i,
  .sidebar-alt-partial #sidebar-alt:hover .sidebar-brand i,
  .sidebar-alt-visible-lg #sidebar-alt .sidebar-brand i {
    display: inline-block;
  }
  /* Main Sidebar */
  .sidebar-partial #main-container {
    margin-left: 65px;
  }
  .sidebar-partial #sidebar:hover + #main-container,
  .sidebar-visible-lg #main-container {
    margin-left: 200px;
  }
  .sidebar-partial.footer-fixed #main-container #page-content + footer,
  .sidebar-partial.header-fixed-top header.navbar-fixed-top,
  .sidebar-partial.header-fixed-bottom header.navbar-fixed-bottom {
    left: 65px;
  }
  .sidebar-partial #sidebar:hover + #main-container header.navbar-fixed-top,
  .sidebar-partial #sidebar:hover + #main-container header.navbar-fixed-bottom,
  .sidebar-partial #sidebar:hover + #main-container #page-content + footer,
  .sidebar-visible-lg.header-fixed-top header.navbar-fixed-top,
  .sidebar-visible-lg.header-fixed-bottom header.navbar-fixed-bottom,
  .sidebar-visible-lg.footer-fixed #main-container #page-content + footer {
    left: 200px;
  }
  /* Alternative Sidebar */
  .sidebar-alt-partial #main-container {
    margin-right: 65px;
  }
  .sidebar-alt-partial #sidebar-alt:hover ~ #main-container,
  .sidebar-alt-visible-lg #main-container {
    margin-right: 200px;
  }
  .sidebar-alt-partial.footer-fixed #main-container #page-content + footer,
  .sidebar-alt-partial.header-fixed-top header.navbar-fixed-top,
  .sidebar-alt-partial.header-fixed-bottom header.navbar-fixed-bottom {
    right: 65px;
  }
  .sidebar-alt-partial #sidebar-alt:hover ~ #main-container header.navbar-fixed-top,
  .sidebar-alt-partial #sidebar-alt:hover ~ #main-container header.navbar-fixed-bottom,
  .sidebar-alt-partial #sidebar-alt:hover ~ #main-container #page-content + footer,
  .sidebar-alt-visible-lg.header-fixed-top header.navbar-fixed-top,
  .sidebar-alt-visible-lg.header-fixed-bottom header.navbar-fixed-bottom,
  .sidebar-alt-visible-lg.footer-fixed #main-container #page-content + footer {
    right: 200px;
  }
  /* Main Sidebar - Mini mode */
  .sidebar-mini.sidebar-visible-lg-mini #main-container {
    margin-left: 40px;
  }
  .sidebar-mini.sidebar-visible-lg-mini #main-container header.navbar-fixed-top,
  .sidebar-mini.sidebar-visible-lg-mini #main-container header.navbar-fixed-bottom,
  .sidebar-mini.sidebar-visible-lg-mini #main-container #page-content + footer {
    left: 40px;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar {
    overflow: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 1029;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-content {
    width: 40px;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-nav-mini-hide,
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-header > span {
    display: none;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-header {
    padding: 0;
    margin: 5px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-nav li {
    position: relative;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-nav .sidebar-nav-menu + ul {
    position: absolute;
    width: 220px;
    top: 0;
    right: -220px;
    margin: 0;
    padding: 6px 5px;
    border-left: 3px solid #7dc419;
    background-color: #232824;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-nav ul a {
    margin: 0;
    padding-left: 10px;
    border-left: none;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-nav ul ul {
    margin-left: 0;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-nav > li.active > ul {
    display: none;
  }
  .sidebar-mini.sidebar-visible-lg-mini #sidebar .sidebar-nav .sidebar-nav-menu.open + ul {
    display: block !important;
  }
  /* Nav Horizontal */
  .nav-horizontal a {
    text-align: center;
    min-width: 130px;
    padding: 12px 15px;
  }
  .nav-horizontal i {
    display: block;
    height: 55px;
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 42px;
    padding: 10px;
  }
}

/* Large devices, Desktops (>1199px) */
@media screen and (min-width: 1200px) {
  /* Main Layout */
  .header-fixed-top .sidebar-content,
  .header-fixed-bottom .sidebar-content {
    padding-bottom: 0;
  }
  /* General */
  article p {
    font-size: 19px;
    line-height: 1.9;
  }
}

/*
=================================================================
RETINA
=================================================================
*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5) {
  /* If you did use a CSS image background, you can put your high resolution image here */
}

/*
=================================================================
PRINT
=================================================================
*/
@media print {
  #sidebar, #sidebar-alt, .content-header, .breadcrumb-top, .block-title .block-options, #page-content + footer, #to-top {
    display: none !important;
  }
  #page-content {
    min-height: 0 !important;
    height: auto !important;
    padding: 0 !important;
  }
  #main-container {
    margin: 0 !important;
  }
  .block, .block.full {
    border: none !important;
    padding: 0 !important;
  }
  .block-title {
    margin: 0 0 20px !important;
  }
}

/* Themes */
/*
=================================================================
THEME DEFAULT
=================================================================
*/
.themed-color-default {
  color: #7dc419 !important;
}

.themed-border-default {
  border-color: #7dc419 !important;
}

.themed-background-default {
  background-color: #7dc419 !important;
}

.themed-color-dark-default {
  color: #232824 !important;
}

.themed-border-dark-default {
  border-color: #232824 !important;
}

.themed-background-dark-default {
  background-color: #232824 !important;
}

/*
=================================================================
THEME DEFAULT
=================================================================
*/

.themed-color-default {
    color: $color_highlight !important;
}

.themed-border-default {
    border-color: $color_highlight !important;
}

.themed-background-default {
    background-color: $color_highlight !important;
}

.themed-color-dark-default {
    color: $color_dark !important;
}

.themed-border-dark-default {
    border-color: $color_dark !important;
}

.themed-background-dark-default {
    background-color: $color_dark !important;
}
/*
 *  Document   : main
 *  Author     : bhWebDesigner
 *  Description: Main SCSS file where all required variables and styles are imported
 *
 */

/* Template Variables */
@import "variables";

/* Main Template */
@import "main/layout";
@import "main/header";
@import "main/sidebar_nav";
@import "main/content";
@import "main/pages";
@import "main/bs_overwrite";
@import "main/extras";

/* Themes */
@import "themes";
// @import "christmas";


